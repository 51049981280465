import React from 'react'
// import catLogo from '../assets'
import '../App.css';

const Header = () => {
    return <div>

                <div className="header-container">                
                
                {/* <div className='logo'>
                    <img src={catLogo} alt="Angy Kittens Logo" />
                </div> */}
                    <div className='menu'>
                        <div className="menu-links">
                        <a className="dex" href="https://dexscreener.com/solana/fqef2t9mwkg6dsj7d2sn5pzitiewrmz8ow1berdnrptf" target="_blank" rel="noreferrer">Dex</a>
                        <a className="twitter" href="https://twitter.com/catwifduraglol" target="_blank" rel="noreferrer">Twitter</a>
                        <a className="telegram" href="https://t.me/catwifdurag_verify" target="_blank" rel="noreferrer">Telegram</a>
                        <a className="buy" href="https://raydium.io/swap/?outputCurrency=A5xkGZsQsbGfut5xxkDpx5CjMeUj14QtLH5HB7xdxecC">Buy</a>
                        </div>
                    </div>  

                </div>

            </div>
    
}

export default Header