import React from 'react'
import logoCWD from '../assets/CWD_CARTOON.png'

const Hero = () => {
  return <div className="hero-section-container">
    <div className="hero-info-wrapper">
        <div className="hero-info-text">
            <h1 className="title">
                cats wif durags 
            </h1>
            <h2>$DURAG, The next "wif" 🚀 on the <span className="solana">Solana</span> network.
            </h2>
            
            <p className='hero-info-description'>
                <i>We're just some cats wif some durags. Simple.</i>
            </p>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
        </div>
    </div>
        <div className="hero-image-container">
            <div>
                <img className="hero-image" src={logoCWD} alt="Angy Kittens"/>
            </div>
        </div>
  </div>
}

export default Hero