import React from 'react'


const Main = () => {
  return <div>
<div className="hero-section-container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
    <div className="hero-info-wrapper">
        <div className="hero-info-text" style={{ textAlign: 'center' }}>
            <h2 style={{ fontSize: '1.70rem' }}>Get ready to swag out with some <span style={{ color: "#00ff00"}}>$DURAG</span>. The freshest coin to hit the Solana network. Every time our market cap hits a new milestone of $100k, we're not just celebrating - we're sharing. The dev team will sell $1000 worth of tokens and donate the full amount to charities working tirelessly to support stray cats.<br></br><br></br><span style={{ fontSize: '3rem' }}>Join the cats wif durags.</span></h2>
        </div>
          <div className="meme-box">
          {/* this div will have a 2x3 grid that will hold images */}
          <div className="meme-grid">
        </div>
      </div>
    </div>
  </div>
</div>  
}  


export default Main
