import React from 'react';
import './App.css';
import Header from './components/Header';
import Hero from './components/Hero';
import Stats from './components/Stats';
import Main from './components/Main';

function App() {
  return (
      <div className="home">
      <Header />
      <Hero />
      <br></br>
      <Stats />
      <Main />
      </div>
  );
}

export default App;
